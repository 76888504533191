.horizontalBand {
    display: flex;
    background-color: darkseagreen;
    width: 100%;
    height: 220px;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    font-family: Roboto;
}

.service {
    margin-bottom: 60px;
}

.servicesBox {
    flex: 2 1 0px;
    box-shadow: 1px 1px 5px 1px gray;
    padding: 20px;
    margin-bottom: 50px;
    border: 10px solid transparent;
    color: black;
    font-family: Monaco;
    background-color: mediumseagreen;
}

.servicesBoxMin {
    flex: 2 1 0px;
    box-shadow: 1px 1px 5px 1px gray;

    margin-bottom: 50px;
    border: 10px solid transparent;
    color: black;
    font-family: Monaco;
    background-color: mediumseagreen;
    height: 400px;
    font-size: 13px;
}

.servicesBox2 {
    flex: 2 1 0px;
    box-shadow: 1px 1px 5px 1px gray;
    padding: 20px;
    margin-bottom: 50px;
    border: 10px solid transparent;
    color: black;
    font-family: Monaco;
    background-color: darkseagreen;
}

.servicesBox2Min {
    flex: 2 1 0px;
    box-shadow: 1px 1px 5px 1px gray;

    margin-bottom: 50px;
    border: 10px solid transparent;
    color: black;
    font-family: Monaco;
    background-color: darkseagreen;
    height: 400px;
    font-size: 13px;
}

.icon2 {
    width: 40px;
    height: 40px;
    color: white;
}

.icon2Min {
    width: 25px;
    height: 25px;
    color: white;
}

.icon2Min4 {
    width: 20px;
    height: 20px;
    color: white;
}

.icon2Min2 {
    width: 30px;
    height: 30px;
    color: white;
    margin-right: 10px;
}

.icon2Min3 {
    width: 15px;
    height: 15px;
    color: white;
}

.icon2Max {
    width: 60px;
    height: 60px;
    color: white;
}

.serviceBoxPar {

}
.serviceBoxPar2 {
    margin-left: 35%;
    margin-right: 35%;
}

.par {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
    margin-right: 20px;
}

.par2 {
    display: flex;
    align-items: center;

    border: none;
    margin-right: 20px;
}

.parMin {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
    margin-right: 10px;
    margin-left: 10px;
}

.footer2 {
    display: flex;
    background-color: lightgrey;
    width: 100%;
    height: 100px;
    justify-content: flex-start;
    align-items: center;
    font-family: Roboto;
    margin-top: 30px;

}