body {
    font-family: Arial, Helvetica, sans-serif;
    background-color: white;
}

.colorwhite {
    background-color: white;
}

.navbar {
    width: 80%;
    overflow: auto;
    padding: 20px;
    opacity: 0.8;
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: white;


    display: block;
    margin-left: auto;
    margin-right: auto;
}

.navbarMin {
    width: 80%;
    padding-top: 10px;
    padding-bottom: 10px;
    opacity: 0.8;
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: white;
    margin-right: 10%;
    margin-left: 10%;


}

.navdiv {
    background-color: white;
}
/*
.navbar a {
    padding: 20px;
    color: black;
    text-decoration: none;
    font-size: 17px;
    margin-right: 20px;
    background-color: white;
}

 */

a:link { text-decoration: none; }

.logo {
    width: 210px; /* 300px o 20%*/
    height: 60px; /* 60px o 4% */
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.logoMin {
    width: 210px; /* 300px o 20%*/
    height: 60px; /* 60px o 4% */
    margin-top: 15px;

    display: block;
    margin-left: auto;
    margin-right: auto;
}

.head {
    display: flex;
    justify-content: space-between;
    background-color: white;
}

.log {
    /*
    border: none;
    background-color: white;
    margin-right: 20px;
     */
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: white;
    margin-right: 20px;
}

.logMin {
    display: none;
}

.iconLogin {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.responsiveMin {
    padding: 5px;
    color: black;
    text-decoration: none;
    font-size: 10px;
    margin-right: 5px;
    background-color: white;

}

.responsiveMax {
    padding: 20px;
    color: black;
    text-decoration: none;
    font-size: 17px;
    margin-right: 20px;
    background-color: white;
}