.houseImage {
    width: 200px;
    height: 150px;
}

.footer3 {
    display: flex;
    background-color: lightgrey;
    width: 100%;
    height: 100px;
    justify-content: flex-start;
    align-items: center;
    font-family: Roboto;
    margin-top: 100px;

}

.immobilititle {
    color: white;
    font-family: Monaco;
    font-size: 70px;
}

.immobiliimage {
    width: 100%;
    height: 220px;
    filter: brightness(50%);

}

.immobiliimageMin {
    width: 100%;
    height: 160px;
    filter: brightness(50%);

}

.contactsBoxImm {
    flex: 2 1 0px;
    box-shadow: 1px 1px 5px 1px gray;
    padding: 20px;
    margin-bottom: 50px;
    border: 10px solid transparent;
    color: black;
    margin-right: 10px;
    margin-left: 10px;
    font-family: Monaco;
    width: 350px;
}

.contactsBoxImmMin {
    flex: 2 1 0px;
    box-shadow: 1px 1px 5px 1px gray;
    padding: 20px;
    margin-bottom: 50px;
    border: 10px solid transparent;
    color: black;
    font-family: Monaco;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

}

.footerImm {
    display: flex;
    background-color: lightgrey;
    width: 100%;
    height: 60px;
    justify-content: flex-start;
    align-items: center;
    font-family: Roboto;
    margin-top: 300px;
    font-size: 10px;
}

.footerImmMin {
    display: flex;
    background-color: lightgrey;
    width: 100%;
    height: 60px;
    justify-content: flex-start;
    align-items: center;
    font-family: Roboto;
    margin-top: 500px;
    font-size: 10px;
}

.containerMin {
    position: relative;
    text-align: center;
    color: white;
    margin-bottom: 20px;
}

.contactsContainerMin {
    display: flex;

    flex-direction: column;
}

.venduto {
    position: absolute;
    color: red;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 70px;
    font-family: Monaco;
  }