.icon {
    width: 40px;
    height: 40px;
    color: darkseagreen;
}

.map {
    border: 0;
}

.officeImage {
    width: 591px;
    height: 763px;
    margin-left: 10px;
    /*
    width: 394px;
    height: 509px;
     */
}

.officeImageMin {
    width: 195px;
    height: 274px;
    margin-left: 10px;
    /*
    width: 394px;
    height: 509px;
     */
}

.horizontalContainer {
    display: flex;
    flex-direction: column;
}

.verticalContainer {
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 150px;
}

.contactsContainer {
    display: flex;
    align-items: baseline;
    justify-content: center;
    align-content: flex-start;
    align-self: flex-start;
}

.contactsBox {
    flex: 2 1 0px;
    box-shadow: 1px 1px 5px 1px gray;
    padding: 20px;
    margin-bottom: 50px;
    border: 10px solid transparent;
    color: black;
    margin-right: 10px;
    margin-left: 10px;
    font-family: Monaco;
}

.contactsBoxMin {
    flex: 4 1 0px;
    width: 60px;
    box-shadow: 1px 1px 5px 1px gray;
    padding: 10px;
    margin-bottom: 80px;
    border: 10px solid transparent;
    color: black;
    margin-right: 5px;
    margin-left: 5px;
    font-family: Monaco;
    font-size: 10px;
    height: 90px;
}

.contactsBoxMinX {
    flex: 4 1 0px;
    width: 60px;
    box-shadow: 1px 1px 5px 1px gray;
    padding: 10px;
    margin-bottom: 80px;
    border: 10px solid transparent;
    color: black;
    margin-right: 5px;
    margin-left: 5px;
    font-family: Monaco;
    font-size: 9px;
    height: 90px;

}

.contactsBoxMin2 {
    flex: 2 1 0px;
    height: 90px;
    /*width: 90px; */
    width: 125px;
    box-shadow: 1px 1px 5px 1px gray;
    padding: 10px;
    margin-bottom: 20px;
    border: 10px solid transparent;
    color: black;
    /* margin-left: 40px; */
    font-family: Monaco;
    font-size: 10px;

    padding-bottom: 25px;
}

.contactsBox:hover {
    cursor: pointer;
}

.contactsBoxEmpty {
    flex: 1 1 0px;
}

a {
    color: black;
}
a:hover {
    color: lightgreen;
}

.footer {
    display: flex;
    background-color: lightgrey;
    width: 100%;
    height: 60px;
    justify-content: flex-start;
    align-items: center;
    font-family: Roboto;
    margin-top: 300px;
    font-size: 10px;
}

.contactMin {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 18px;
}