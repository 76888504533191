.adminContainer {

}

.close {
    position: absolute;
    top: 0px;
    left: 90%;
}

.adminContactsBox {

    flex: 2 1 0px;
    box-shadow: 1px 1px 5px 1px gray;
    padding: 20px;
    margin-bottom: 50px;
    border: 10px solid transparent;
    color: black;
    margin-right: 10px;
    margin-left: 10px;
    font-family: Monaco;
    position:relative;
}