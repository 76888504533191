.chisiamo {
    margin-left: 30px;
    margin-bottom: 80px;
    margin-right: 30px;
}

.chisiamoH {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    margin-bottom: 40px;
    font-family: Monaco;
    color: darkseagreen;
}

.parChisiamo {
    display: flex;
    align-items: center;
    text-align: justify;
    border: none;

    line-height: 2.0;
}

.serviceimageMin {
    width: 100%;
    height: 160px;
    filter: brightness(60%);
}