.login {
    font-family: Helvetica;
    text-align: center;
    width: 300px;
    margin-left: 40%;
    margin-right: 40%;
    border: solid rgba(255, 255, 255, .5);
    border-width: thin;
    border-radius: 10px;
    padding: 20px;
}

.logbutton {
    width: 270px;
    background-color: white;
    border: none;
    color: black;
    padding: 16px 32px;
    text-decoration: none;
    margin-top: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 5px;
    font-family: Helvetica;
    font-size: large;
    font-weight: bold;
    background-color: rgba(255, 255, 255, .5);
}

.log:hover {
    background-color: snow;
}

.firstLog {
    padding-top: 60px;
    background-color: darkseagreen;
    height: 900px;
    width: 100%;
}

.text {
    width: 200px;
    background-color: white;
    border: none;
    color: black;
    padding: 16px 32px;
    text-decoration: none;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .5);
}

.user {
    text-align: left;
    color: white;
}

.logTitle {
    color: white;
}

.ReqForm {
    text-align: center;
    font-family: Helvetica;
    background-color: forestgreen;
    border-radius: 20px;
    box-sizing: border-box;
    height: 500px;
    padding: 20px;
    width: 30%;
    margin-left: 35%;
    margin-right: 35%;
    margin-top: 30px;
}

input[name="ogg"], [name="rec"], [name="zona"], [name="price"], [name="tipo"] {
    background-color: rgba(255, 255, 255, .5);
    border-radius: 10px;
    width: 90%;
    height: 30px;
    padding: 10px;
    margin: 10px;
    border: none;
}

input::-webkit-input-placeholder {
    color: black; /*Change the placeholder color*/
    opacity: 0.5; /*Change the opacity between 0 and 1*/
    font-size: 15px;
}