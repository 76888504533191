.App {
  text-align: center;
  background-color: gainsboro;
}

.content {
  color: seagreen;
  margin-bottom: 80px;
  font-family: Monaco;
}

.App-logo {
  /*
  height: 40vmin;
  pointer-events: none;

   */
}˙

.homeImage {
  width: 100%;
  height: 525px;
  margin-top: 10px;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
