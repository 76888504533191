.homepageimage {
    width: 100%;
    height: 525px;
    
    margin-bottom: 20px;
}

.homepageimageMin {
    width: 100%;
    height: 41%;
    /*height: 202px;*/
    margin-bottom: 20px;
}

.titleMin {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 18px;
}

p {
    font-family: Monaco;
}

h2 {
    font-family: Monaco;
    color: seagreen;
}
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    font-family: Monaco;
}
.serviceimage {
    width: 100%;
    height: 220px;
    filter: brightness(60%);
}

.container {
    position: relative;
    text-align: center;
    color: white;
    margin-bottom: 60px;
}

.containerImm {
    position: relative;
    text-align: center;
    color: white;
    margin-bottom: 42px;
}